// Font Family
$body-font: 'Poppins', sans-serif;
$heading-font: 'Poppins', sans-serif;


// Colors ---------------

$white: #ffffff;
$black: #000000;
$grey: #f1f1f1;
$lightgrey: #f9f9f9;

// Body Color
$body-color: #333;
$primary: #FFFA0F;
$link-color: #f15445;

// Heading Color
$heading-color: #333333;

//Antibabylon Countries
$armenia:#F8A525;
$georgia:#E53F1E;
$moldova:#9CD73D;
$ukraine:#3D71DD;
$germany:#FFFA0F;

// Theme Colors
$theme-color-1: #E65B1C;
$theme-color-2: #f15445;
$theme-color-3: #51becd;
$theme-color-4: #e59701;

// Responsive Variables
$extraBig-device: 'only screen and (min-width: 1600px)';
$laptop-device: 'only screen and (min-width: 1200px) and (max-width: 1599px)';
$laptop-large-device : 'only screen and (min-width: 1200px) and (max-width: 1499px)';
$desktop-device: 'only screen and (min-width: 992px) and (max-width: 1199px)';
$tablet-device:'only screen and (min-width: 768px) and (max-width: 991px)';
$large-mobile:'only screen and (max-width: 767px)';
$small-mobile:'only screen and (max-width: 575px)';
$extra-small-mobile: 'only screen and (max-width: 479px)';

