@mixin background-svg($color) {
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(../images/frame-portrait.svg);
  mask-image: url(../images/frame-portrait.svg);
  background-color: $color;
}

@mixin country-label-underline($color) {
  backface-visibility: hidden;
    transform-style: preserve-3d;
    transform: translateZ(0);
    will-change: transform;
    content: "";
    width: 100%;
    height: 5px;
    position: absolute;
    bottom: 6px;
    left: 0px;
    z-index: -1;
    background: $color;
    transition: all 0.35s cubic-bezier(0.07, 0.71, 0.1, 0.9);
    transform: translate3d(0, 100%, 0);
}

.country-label {
  position: relative;
  z-index: 0;
  font-size: 1.25rem;

  &.armenia {
    &:after { @include country-label-underline($armenia); }
  }
    &.georgia {
      &:after { @include country-label-underline($georgia); }
      }
    &.moldova {
      &:after {@include country-label-underline($moldova); }
    }
    &.ukraine {
      &:after {@include country-label-underline($ukraine); }
    }

    &.germany {
      &:after {@include country-label-underline($germany); }
    }
}

.container-person {
  cursor: pointer;
} 

.person-title {
  font-family: "Dharma Punk 2";
}

.poet {

.person-title {
  font-size: 3.25rem;
  line-height: 2.5rem;
  width: fit-content;
}
 .photo-credit {
  margin-right: 10px;
}

.poem-title {
  width: fit-content;
    font-size: 2.75rem;
    line-height: 4rem;
    text-transform: uppercase;

    &.georgia {
    color: $georgia;
  }
    &.armenia {
    color: $armenia;
  }
    &.moldova {
    color: $moldova;
  }
    &.ukraine {
    color: $ukraine;
  }
  &.germany {
    color: $black;
  }
}

.poem-subtitle {
  font-size: 1.75rem;
  color: $heading-color;
}


.poem-author {
  font-size: .75rem;
  margin-left: 2px;
  font-style: italic;
}

}

.jury {
.person-title {
  font-size: 2.75rem;
  line-height: 1.75rem;
}

.person-image {
  img {
    margin-top: 5px;
  }
}
}

.person-image {
  position: relative;
  @include background-svg(#000);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 25px 15px 15px 15px;

  &:hover {
    &.armenia {@include background-svg($armenia); }
    &.georgia {@include background-svg($georgia); }
    &.moldova {@include background-svg($moldova); }
    &.ukraine {@include background-svg($ukraine); }
    &.germany {@include background-svg($germany); }
  }

  img {
    width: 100%;
    height:auto;
    } 
}

.photo-credit {
  text-align: right;
  font-size: 10px;
}

.jury {
  .person-image {
  padding: 10px;
}
.photo-credit {
  margin-right: 5px;
}
}

.team-member {

  width: 85%;
  margin: auto;

  .person-image {
    max-width: 100%;
    padding: 8.5px;
    background-color: $primary;

    img {
      margin-top: 2px;
    }
  }

  .person-title {
  font-size: 2.10rem;
  line-height: 1.45rem;
  //font-family: "Poppins";
  height: 40px;
}

i:hover {
  color: black
}

.photo-credit {
  margin-right: 5px;
}

}

