/*----------------------------------------*/
/*  04. About CSS
/*----------------------------------------*/

/*-- About Image --*/
.about-image {
    & img {
        width: 100%;
    }
}

/*-- About Image --*/
.about-content {
    padding-left: 85px;
    &  .block-title {
        font-size: 24px;
        line-height: 1;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0.75px;
        margin-bottom: 28px;
        // responsive
        @media #{$large-mobile} {
            margin-bottom: 20px;
        }
    }
    & p {
        letter-spacing: 0.75px;
    }
    
    // Responsive
    @media #{$laptop-device}{
        padding-left: 45px;
    }
    @media #{$desktop-device}{
        padding-left: 15px;
    }
    @media #{$tablet-device}{
        padding-left: 15px;
    }
    @media #{$large-mobile}{
        padding-left: 15px;
    }
}

/*-- Personal Info --*/
.personal-info {
    margin-bottom: 34px;
    padding-top: 10px;
    // responsive
    @media #{$large-mobile} {
        margin-bottom: 42px;
    }
    & li {
        font-size: 14px;
        line-height: 24px;
        font-family: $heading-color;
        color: $body-color;
        margin-bottom: 15px;
        & span {
            font-weight: 600;
            color: $heading-color;
            margin-right: 10px;
        }
    }
    &:last-child {
        margin-bottom: 0;
        & li {
            margin-bottom: 0;
        }
    }
}

/*-- Skill Wrap --*/
.skill-wrap {
    float: left;
    width: 100%;
    clear: both;
}

/*-- Single Skill --*/
.single-skill {
    float: left;
    width: 100%;
    clear: both;
    margin-bottom: 30px;
    &:last-child {
        margin-bottom: 0;
    }
    & span {
        display: block;
        font-size: 14px;
        line-height: 1;
        color: $body-color;
        font-family: $heading-font;
        letter-spacing: 1px;
        margin-bottom: 12px;
    }
    & .skill-bar {
        display: block;
        width: 100%;
        height: 3px;
        background-color: #dbdbdb;
        padding: 1px;
        & .skill-progress {
            display: block;
            height: 100%;
            background-color: $heading-color;
            position: relative;
            &::before {
                content: attr(data-progress);
                position: absolute;
                right: 0;
                bottom: 15px;
                font-size: 14px;
                line-height: 1;
                color: $body-color;
                font-family: $heading-font;
                letter-spacing: 1px;
            }
            &::after {
                content: "";
                position: absolute;
                right: 0;
                top: -1px;
                height: 3px;
                width: 7px;
                background-color: $heading-color;
            }
        }
    }
}

/*---- about feelancer start ----*/
.freelancer-title {
    text-transform: capitalize;
    position: relative;
    b {
        font-weight: 600;
    }
    &:before {
        width: 100px;
        height: 2px;
        content: '';
        position: absolute;
        background-color: #f6f6f6;
        left: 0;
        bottom: -10px;
    }
}
.freelancer-subtitle {
    padding: 30px 0;
    font-weight: 500;
    margin-bottom: 0;
}
/*---- about feelancer end ----*/