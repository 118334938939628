/*----------------------------------------*/
/*  13. Footer CSS
/*----------------------------------------*/

#footer-separator {

  position: relative;
    background-color: $white;
    text-align: center;
    width: 100%;
    z-index: 1;

&::before {
    width: 100%;
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    top: -75px;
    -webkit-transform: skewY(1deg);
    -moz-transform: skewY(1deg);
    -ms-transform: skewY(1deg);
    -o-transform: skewY(1deg);
    transform: skewY(1deg);
    background-color: $white;
    z-index: 2;
}

.container {
    position: relative;
    height: 100px;
}
}

.footer-section {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
        
    // Responsive
    &.position-fixed{
        @media #{$tablet-device}{
            position: static !important;
        }
        @media #{$large-mobile}{
            position: static !important;
        }
    }
    & .container-fluid {
        padding: 0 170px;

        // Responsive
        @media #{$laptop-device}{
            padding: 0 50px;
        }
        @media #{$desktop-device}{
            padding: 0 40px;
        }
        @media #{$tablet-device}{
            padding: 0 40px;
        }
        @media #{$large-mobile}{
            padding: 0 40px;
        }
        @media #{$small-mobile}{
            padding: 0 25px;
        }
    }
}

/*-- Footer Copyright --*/
.footer-copyright {
    & p {
        font-size: 14px;
        text-transform: uppercase;
        margin: 0;
        line-height: 30px;
        & a {
            color: $body-color;
            &:hover {
                color: $heading-color;
            }
        }

        // Responsive
        @media #{$tablet-device}{
            text-align: center;
        }
        @media #{$large-mobile}{
            text-align: center;
            font-size: 13px;
        }
    }
}

.footer-partners {
    background-image: url(../images/bg/footer-bg.svg);
    background-position: left;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 200px;
    margin-left: -2px!important;
    margin-bottom: -2px!important;

    img {
        max-width: 95%;
    }

    @media #{$desktop-device, $tablet-device, $large-mobile, $small-mobile, $extra-small-mobile}{
        min-height: 215px;
        min-width: 101%;
    }
}

.footer-title {
  height: 3rem;
}

.footer-social {
  display: flex;

  & a {
    color: $body-color;
    margin-right: 12px;
    text-decoration: none!important;

    & i {
      display: block;
      font-size: 24px;
      line-height: 24px;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.footer-rows {
    font-size: 1rem;
    .row {
        margin: auto;

        [class*="col-"] {
            float: none;
            display: table-cell;
            vertical-align: top;
            padding: 0;
                }

    }
}