/*----------------------------------------*/
/*  08. Sidebar CSS
/*----------------------------------------*/

/*-- Sidebar --*/
.sidebar {
    margin-bottom: 27px;
    float: left;
    width: 100%;
    overflow: hidden;
    &:last-child {
        margin-bottom: 0;
    }
    
    /*-- Title --*/
    & .title {
        font-size: 20px;
        font-weight: 600;
        line-height: 1;
        text-transform: uppercase;
        margin-bottom: 18px;
    }

    // Responsive
    @media #{$desktop-device}{
        & .title {
            font-size: 18px;
        }
    }
    @media #{$small-mobile}{
        & .title {
            font-size: 18px;
        }
    }
}

/*-- Sidebar Search --*/
.sidebar-search {
    position: relative;
    & input {
        background-color: transparent;
        width: 100%;
        border-width: 0 0 1px;
        border-style: solid;
        border-color: $body-color;
        font-size: 14px;
        line-height: 18px;
        color: $body-color;
        font-family: $heading-font;
        float: left;
        padding-bottom: 10px;
        padding-right: 30px;
    }
    & button {
        position: absolute;
        right: 0;
        top: 0;
        padding: 0;
        background-color: transparent;
        border: none;
        line-height: 18px;
        padding-bottom: 10px;
        & i {
            font-size: 14px;
            line-height: 18px;
        }
        &:hover {
            color: $heading-color;
        }
    }
}

/*-- Sidebar About --*/
.sidebar-about {
    & p {
        font-size: 14px;
        line-height: 30px;
    }
}

/*-- Sidebar List --*/
.sidebar-list {
    & li {
        margin-bottom: 6px;
        & a {
            display: block;
            font-size: 14px;
            line-height: 24px;
            color: $body-color;
            &:hover {
                color: $heading-color;
            }
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}

/*-- Sidebar Post --*/
.sidebar-post {
    & li {
        margin-bottom: 21px;
        & a {
            display: block;
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
            color: $heading-color;
            margin-bottom: 6px;
            &:hover {
                color: $heading-color;
            }
        }
        & span {
            font-size: 14px;
            display: block;
            color: $body-color;
            line-height: 1;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}

/*-- Sidebar Tags --*/
.sidebar-tags {
    & a {
        font-size: 14px;
        line-height: 24px;
        color: $body-color;
        margin-right: 5px;
        &::after {
            content: ',';
        }
        &:last-child {
            &::after {
                display: none;
            }
        }
        &:hover {
            color: $heading-color;
        }
    }
}