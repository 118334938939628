@mixin background-svg-even($color) {
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(../images/events/event-even.svg);
  mask-image: url(../images/events/event-even.svg);
  background-color: $color;
}

@mixin background-svg-odd($color) {
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(../images/events/event-odd.svg);
  mask-image: url(../images/events/event-odd.svg);
  background-color: $color;
}

.event {
  
a {
  width: 100%;
    color: black;
    text-decoration: none;
}

.event-title {
  font-family: "Dharma Punk 2";
  font-size: 3rem;
  line-height: 2rem;
}

.event-details {
  display: table;
  width: 100%;
  padding: 30px;
  margin-bottom: 50px;

  i {
    font-size: 2rem;
    position: absolute;
    left: -15px;
  }

  &:hover {
    i {
      transform: scale(1.25); 
      transition: top ease 1.5s;
      left: 0px;
    }
  }
}

.event-date {
      font-family: 'Menlo', sans-serif;
      font-size: 1.25rem;
      font-weight: 600;
      margin-bottom: 15px;
}

.event-site {
  font-weight: 600;
}
}

.events-list .event:nth-child(odd) {

  .event-details {
  position: relative;
  @include background-svg-odd($grey);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 25px 15px 15px 15px;
  }
}


.events-list .event:nth-child(even) {

  .event-details {
  position: relative;
  @include background-svg-even($grey);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 45px 25px 25px;
  }
}

.events-list .event .event-details:hover {
    color: #333;

    &.georgia {@include background-svg-odd($georgia); color: #fff}
    &.armenia {@include background-svg-even($armenia); }
    &.moldova {@include background-svg-odd($moldova); }
    &.ukraine {@include background-svg-even($ukraine); color: #fff}
    &.germany {@include background-svg-odd($germany); }
} 