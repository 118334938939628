/*----------------------------------------*/
/*  05. Service CSS
/*----------------------------------------*/

.service-bg {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  .service-item {
    & p {
      max-width: 100%;
    }
  }

  [class*='col'] {
    &:last-child {
      margin-bottom: 0 !important;
    }

    &:nth-last-child(2){
      @media #{$tablet-device} {
        margin-bottom: 0 !important;
      }
    }
  }
}

/*-- Service Item --*/
.service-item {
  & h3 {
    font-size: 24px;
    line-height: 1;
    font-weight: 600;
    color: $heading-color;
    text-transform: uppercase;
    letter-spacing: 0.75px;
    margin-bottom: 20px;
  }

  & p {
    max-width: 360px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  // Responsive
  @media #{$desktop-device}{
    & h3 {
      font-size: 20px;
    }
  }
  @media #{$tablet-device}{
    & h3 {
      font-size: 20px;
    }
  }
  @media #{$large-mobile}{
    & h3 {
      font-size: 18px;
    }
  }
}