/*----------------------------------------*/
/*  07. Testimonial CSS
/*----------------------------------------*/

/*Testimonial Slider*/
.testimonial-slider {
    width: calc(100% - 100px);
    // Responsive
    @media #{$desktop-device, $large-mobile} {
        width: 100%;
    }
}

/*Testimonial*/
.testimonial {
    padding-left: 40px;
    & .inner {
        background-color: #f7f7f7;
        display: flex;
        flex-wrap: wrap;
        padding: 30px 0;
        & .author {
            transform: translateX(-40px);
            width: 100px;
            align-self: flex-end;
            & img {
                max-width: 100px;
                margin-left: auto;
            }
            & .info {
                white-space: nowrap;
                margin-top: 30px;
                & .name {
                    line-height: 1;
                }
                & .title {
                    float: left;
                    line-height: 1;
                    opacity: 0.5;
                }
            }
        }
        & .content {
            flex: 1 0 calc(100% - 100px);
            max-width: calc(100% - 100px);
            padding: 50px 50px 50px 20px;
            & p {
                font-size: 20px;
                line-height: 1.5;
                opacity: 0.5;
            }
        }
    }
    // Responsive
    @media #{$large-mobile} {
        & .inner {
            & .content {
                padding: 20px 40px 20px 0;
            }
        }
    }
    @media #{$extra-small-mobile} {
        padding-left: 0;
        padding-bottom: 38px;
        & .inner {
            padding: 0;
            & .author {
                order: 2;
                padding-left: 30px;
                transform: translateX(0);
                margin-bottom: -38px;
                & .info {
                    margin-top: 15px;
                }
            }
            & .content {
                order: 1;
                padding: 30px;
                max-width: 100%;
                flex: 1 0 100%;
            }
        }
    }
}

/*Testimonial Arrows*/
.testimonial-arrows {
    align-self: flex-end;
    width: 100px;
    display: flex;
    justify-content: flex-end;
    position: relative;
    z-index: 99;
    & .slick-arrow {
        border: none;
        background-color: transparent;
        padding: 0;
        color: #999999;
        line-height: 1;
        &:hover {
            color: $primary;
        }
        &.slick-next {
            margin-left: 20px;
        }
    }
    // Responsive
    @media #{$desktop-device, $large-mobile} {
        width: 100%;
        height: 0;
        top: -40px;
        padding-right: 40px;
    }
    @media #{$extra-small-mobile} {
        top: -35px;
        padding-right: 20px;
    }
}

.section-title {
    &.section-title-bg {
        background-image: url(../images/bg/title-bg-1.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        padding: 70px 0;
    }
}
