@import 'variabls';

.mbn-30 {
    margin-bottom: -30px !important;
}

/*--
    - Custom Row
------------------------------------------*/
@for $i from 0 through 15 {
    .row-#{1 * $i} {
        margin-left: -1px * $i;
        margin-right: -1px * $i;
        
        & > [class*="col"] {
            padding-left: 1px * $i;
            padding-right: 1px * $i;
        }
    }
}
@for $i from 16 through 40 {
    .row-#{1 * $i} {
        margin-left: -1px * $i;
        margin-right: -1px * $i;
        
        // Responsive
        @media #{$laptop-device}{
            margin-left: -15px;
            margin-right: -15px;
        }
        @media #{$desktop-device}{
            margin-left: -15px;
            margin-right: -15px;
        }
        @media #{$tablet-device}{
            margin-left: -15px;
            margin-right: -15px;
        }
        @media #{$large-mobile}{
            margin-left: -15px;
            margin-right: -15px;
        }
        
        & > [class*="col"] {
            padding-left: 1px * $i;
            padding-right: 1px * $i;
        
            // Responsive
            @media #{$laptop-device}{
                padding-left: 15px;
                padding-right: 15px;
            }
            @media #{$desktop-device}{
                padding-left: 15px;
                padding-right: 15px;
            }
            @media #{$tablet-device}{
                padding-left: 15px;
                padding-right: 15px;
            }
            @media #{$large-mobile}{
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
}


/*-- 
    - Margin & Padding
-----------------------------------------*/
/*-- Margin Top --*/
@for $i from 0 through 40 {
    .mt-#{5 * $i}{margin-top: 5px * $i !important;}
}
@for $i from 0 through 40 {
    @media #{$laptop-device}{
        .mt-lg-#{5 * $i}{margin-top: 5px * $i !important;}
    }
}
@for $i from 0 through 40 {
    @media #{$desktop-device}{
        .mt-md-#{5 * $i}{margin-top: 5px * $i !important;}
    }
}
@for $i from 0 through 40 {
    @media #{$tablet-device}{
        .mt-sm-#{5 * $i}{margin-top: 5px * $i !important;}
    }
}
@for $i from 0 through 40 {
    @media #{$large-mobile}{
        .mt-xs-#{5 * $i}{margin-top: 5px * $i !important;}
    }
}

/*-- Margin Bottom --*/
@for $i from 0 through 40 {
    .mb-#{5 * $i}{margin-bottom: 5px *$i !important;}
}
@for $i from 0 through 40 {
    @media #{$laptop-device}{
        .mb-lg-#{5 * $i}{margin-bottom: 5px * $i !important;}
    }
}
@for $i from 0 through 40 {
    @media #{$desktop-device}{
        .mb-md-#{5 * $i}{margin-bottom: 5px * $i !important;}
    }
}
@for $i from 0 through 40 {
    @media #{$tablet-device}{
        .mb-sm-#{5 * $i}{margin-bottom: 5px * $i !important;}
    }
}
@for $i from 0 through 40 {
    @media #{$large-mobile}{
        .mb-xs-#{5 * $i}{margin-bottom: 5px * $i !important;}
    }
}

/*-- Margin Left --*/
@for $i from 0 through 40 {
    .ml-#{5 * $i}{margin-left: 5px * $i !important;}
}
@for $i from 0 through 40 {
    @media #{$laptop-device}{
        .ml-lg-#{5 * $i}{margin-left: 5px * $i !important;}
    }
}
@for $i from 0 through 40 {
    @media #{$desktop-device}{
        .ml-md-#{5 * $i}{margin-left: 5px * $i !important;}
    }
}
@for $i from 0 through 40 {
    @media #{$tablet-device}{
        .ml-sm-#{5 * $i}{margin-left: 5px * $i !important;}
    }
}
@for $i from 0 through 40 {
    @media #{$large-mobile}{
        .ml-xs-#{5 * $i}{margin-left: 5px * $i !important;}
    }
}

/*-- Margin Right --*/
@for $i from 0 through 40 {
    .mr-#{5 * $i}{margin-right: 5px *$i !important;}
}
@for $i from 0 through 40 {
    @media #{$laptop-device}{
        .mr-lg-#{5 * $i}{margin-right: 5px * $i !important;}
    }
}
@for $i from 0 through 40 {
    @media #{$desktop-device}{
        .mr-md-#{5 * $i}{margin-right: 5px * $i !important;}
    }
}
@for $i from 0 through 40 {
    @media #{$tablet-device}{
        .mr-sm-#{5 * $i}{margin-right: 5px * $i !important;}
    }
}
@for $i from 0 through 40 {
    @media #{$large-mobile}{
        .mr-xs-#{5 * $i}{margin-right: 5px * $i !important;}
    }
}

/*-- Padding Top --*/
@for $i from 0 through 40 {
    .pt-#{5 * $i}{padding-top: 5px *$i !important;}
}
@for $i from 0 through 40 {
    @media #{$laptop-device}{
        .pt-lg-#{5 * $i}{padding-top: 5px * $i !important;}
    }
}
@for $i from 0 through 40 {
    @media #{$desktop-device}{
        .pt-md-#{5 * $i}{padding-top: 5px * $i !important;}
    }
}
@for $i from 0 through 40 {
    @media #{$tablet-device}{
        .pt-sm-#{5 * $i}{padding-top: 5px * $i !important;}
    }
}
@for $i from 0 through 40 {
    @media #{$large-mobile}{
        .pt-xs-#{5 * $i}{padding-top: 5px * $i !important;}
    }
}

/*-- Padding Bottom --*/
@for $i from 0 through 40 {
    .pb-#{5 * $i}{padding-bottom: 5px *$i !important;}
}
@for $i from 0 through 40 {
    @media #{$laptop-device}{
        .pb-lg-#{5 * $i}{padding-bottom: 5px * $i !important;}
    }
}
@for $i from 0 through 40 {
    @media #{$desktop-device}{
        .pb-md-#{5 * $i}{padding-bottom: 5px * $i !important;}
    }
}
@for $i from 0 through 40 {
    @media #{$tablet-device}{
        .pb-sm-#{5 * $i}{padding-bottom: 5px * $i !important;}
    }
}
@for $i from 0 through 40 {
    @media #{$large-mobile}{
        .pb-xs-#{5 * $i}{padding-bottom: 5px * $i !important;}
    }
}

/*-- Padding Left --*/
@for $i from 0 through 40 {
    .pl-#{5 * $i}{padding-left: 5px *$i !important;}
}
@for $i from 0 through 40 {
    @media #{$laptop-device}{
        .pl-lg-#{5 * $i}{padding-left: 5px * $i !important;}
    }
}
@for $i from 0 through 40 {
    @media #{$desktop-device}{
        .pl-md-#{5 * $i}{padding-left: 5px * $i !important;}
    }
}
@for $i from 0 through 40 {
    @media #{$tablet-device}{
        .pl-sm-#{5 * $i}{padding-left: 5px * $i !important;}
    }
}
@for $i from 0 through 40 {
    @media #{$large-mobile}{
        .pl-xs-#{5 * $i}{padding-left: 5px * $i !important;}
    }
}

/*-- Padding Right --*/
@for $i from 0 through 40 {
    .pr-#{5 * $i}{padding-right: 5px *$i !important;}
}
@for $i from 0 through 40 {
    @media #{$laptop-device}{
        .pr-lg-#{5 * $i}{padding-right: 5px * $i !important;}
    }
}
@for $i from 0 through 40 {
    @media #{$desktop-device}{
        .pr-md-#{5 * $i}{padding-right: 5px * $i !important;}
    }
}
@for $i from 0 through 40 {
    @media #{$tablet-device}{
        .pr-sm-#{5 * $i}{padding-right: 5px * $i !important;}
    }
}
@for $i from 0 through 40 {
    @media #{$large-mobile}{
        .pr-xs-#{5 * $i}{padding-right: 5px * $i !important;}
    }
}
