.country-map {
  .country {
    text-align: center;
    width: 20%;
    z-index: 1001;

    a {
      font-weight: 500;
      font-size: 1rem;
      color: #333;
      width: auto;
      padding-top: 5px;

    &.active {
      font-size: 1.15rem;
      text-decoration: underline;
      font-weight: 600;
    }

    img {
      max-width: 50px;

      @media #{$large-mobile, $small-mobile, $extra-small-mobile}{
        max-width: 35px;
      }
    }

      &:hover {
        img {
          transform: scale(1.25); 
        }
      }
  }

  &#georgia {
      position: absolute;
      top: 0;
      left: 0;
      
      a {
        min-width: 75px;
      }
    }
  &#armenia {
    position: absolute;
    top: 100px;
    left: 20%;
  }

  &#moldova {
    position: absolute;
    top: 0;
    left: 40%;
  }

  &#ukraine {
    position: absolute;
    top: 100px;
    left: 60%;
  }

  &#germany {
    position: absolute;
    left: 80%;
    top: 0;
  }
  }

  #react-connect-elements-container, #react-connect-elements-container > svg {
    width: 100%;
  }
}

#project-mission {

  position: relative;
    background-color: $white;
    text-align: center;
    position: relative;
    width: 100%;
    z-index: 1;

&::before {
      width: 100%;
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    -webkit-transform: skewY(-4deg);
    -moz-transform: skewY(-4deg);
    -ms-transform: skewY(-4deg);
    -o-transform: skewY(-4deg);
    transform: skewY(-4deg);
    background-color: $grey;
    z-index: 2;
}

.container {
      position: relative;
    z-index: 3;
}
}

#project-dates {

  position: relative;
    background-color: $primary;
    text-align: center;
    position: relative;
    width: 100%;
    z-index: 1;

&::before {
    width: 100%;
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    top: -130px;
    -webkit-transform: skewY(4deg);
    -moz-transform: skewY(4deg);
    -ms-transform: skewY(4deg);
    -o-transform: skewY(4deg);
    transform: skewY(4deg);
    background-color: $primary;
    z-index: 2;
}

.container {
      position: relative;
    z-index: 3;
    top: -135px;

        a {
      width: 100%;
      color: $heading-color;
    }
}
}

#project-phases {

  position: relative;
    background-color: $white;
    text-align: center;
    position: relative;
    width: 100%;
    z-index: 1;

&::before {
      width: 100%;
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    top: -130px;
    -webkit-transform: skewY(-4deg);
    -moz-transform: skewY(-4deg);
    -ms-transform: skewY(-4deg);
    -o-transform: skewY(-4deg);
    transform: skewY(-4deg);
    background-color: $white;
    z-index: 2;
}

.container {
      position: relative;
    z-index: 3;
    top: -35px;
}
}

#project-info {

  position: relative;
    background-color: $white;
    text-align: center;
    position: relative;
    width: 100%;
    z-index: 1;

&::before {
      width: 100%;
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    -webkit-transform: skewY(-1deg);
    -moz-transform: skewY(-1deg);
    -ms-transform: skewY(-1deg);
    -o-transform: skewY(-1deg);
    transform: skewY(-1deg);
    background-color: $grey;
    z-index: 2;
}

.container {
      position: relative;
    z-index: 3;
    top: -25px
}

.d-table-cell {
  vertical-align: middle;

  img {
    max-width: 100%;
    min-width: 175px;

      @media #{$small-mobile, $extra-small-mobile}{
        width: 0!important;
        min-width: 0;
        max-width: 0;
      }
  }
}
}

#project-timeline {
  h3 {
    margin-bottom: 30px;
  }

  .right-table-wrapper {
    position: relative;
    padding-left: 60px;

    .time-line {
      width: 2px;
      position: absolute;
      top: 28px;
      left: 20px;
      right: 0;
      bottom: 0;
      background: $body-color;
    }

    .circle-wrapper {
      z-index: 98;
      position: absolute;
      padding: 4px 0;
      right: 0;
      left: 0;
      width: 42px;

      .circle {
        width: 12px;
        height: 12px;
        border: 2px solid $body-color;
        border-radius: 50%;
        margin: 0 auto;
      }
  }
    }
}

.section#about {
  #project-mission::before, #project-info::before {
    transform: none;
  }
}